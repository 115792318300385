
const WEB_ONLY = true;

import axios from 'axios';
import { getDistance } from 'geolib';

let Region = {};
let TokenStr = "";
let Token = {};
let Cache = {};
let Que = {};
let Customer = null;
let IPinfo;
let API_URL = "";
const TIMEOUT = 10000;

export default {
    setRegion,
    getRegionObj,

    getJSON,
    postJSON,
    postForm,

    getCustomer,
    updateCustomer,
    customerLogout,
    followSite,

    findPosts,
    postsNearMe: findPosts,
    getKm,
    getURLQuery,

    getUser,
    userLogout,

    updateSite,
    getSites,
    getSitePub,
    getSitePubCb,

    getUniq,

    Region: () => Region,
    Customer: () => Customer,
    TokenStr: () => TokenStr,
    Token: () => Token,
    API_URL: () => API_URL,
    setTokenStr
};

async function getRegionObj() {
    let regionData = localStorage.getItem( '__local' );

    if( regionData ){
        regionData = JSON.parse( regionData );
        if( regionData.country ){
            setRegion( regionData.country );
            IPinfo = regionData;
            return regionData;
        }
    }

    let ret = await axios.get( "https://ipapi.co/json/", { timeout: TIMEOUT } );
    if( !ret.data || ret.status != 200 )return null;    
    localStorage.setItem( '__local',JSON.stringify( ret.data ) );
    setRegion( ret.data.country );
    IPinfo = ret.data;
    return ret.data;
}
 
  
function setRegion( country ) {

    country = country.toUpperCase().substr( 0,2 );
    switch( country ){
    case 'EU':
        API_URL = "https://ap-southeast-2.aws.data.mongodb-api.com/app/tcc-azkqf/endpoint/";
        break;

    case 'AU':
        API_URL = "https://ap-southeast-2.aws.data.mongodb-api.com/app/tcc-azkqf/endpoint/";
        break;

    case 'DEV':
        API_URL = "";
        break;

    default:
        API_URL = "https://ap-southeast-2.aws.data.mongodb-api.com/app/tcc-azkqf/endpoint/"; //Default to AU for now

    }

    if( API_URL )Region = country;
    console.log( "REGION", Region );
    return API_URL;
}

let Prom = [];
let Busy = false;
//==========================================
// CUSTOMER
async function getCustomer(){
    // debugger; // eslint-disable-line no-debugger
    if( Customer )return {
        ok: true,
        data: Customer,
        token: Token
    };

    if( Busy )return new Promise( ( resolve ) => {
        Prom.push( resolve );
    } );
    customerToken();//look for tk in url and store.

    let tokenStr = localStorage.getItem( "__cust_tk" );
    if( !tokenStr )return { ok: false, err: "NO TOKEN" };

    Busy = true;
    TokenStr = tokenStr;
    //console.log( TokenStr );

    let url = API_URL + 'customers?&cust_tk=' + TokenStr ;
    let res =  await getJSON( url );
    if( res.ok ){
        console.log( "CUSTOMER", res );
        Customer = res.data;
        Token = res.token;
    }
    if( Prom.length ) Prom.forEach( o => o( res ) );
    Prom = [];
    Busy = false;
    return res;
}

function customerToken(){

    let params = ( new URL( document.location ) ).searchParams;
    let tk = params.get( 'tk' );
    if( tk ){
        localStorage.setItem( "__cust_tk", tk );
        history.replaceState( {},document.title,location.href.split( '?' )[0] );//cleanup url
    }
}


async function updateCustomer( data ){

    let url = 'customers?&edit=1';
    let res =  await postJSON( url, data );
    return res || {};
}

async function followSite( orgSite,unfollow ){

    let data = { remove: false };
    if( unfollow )data.remove = true;
    let url = 'customers?&follow=' + orgSite;
    let res =  await postJSON( url, data );
    return res || {};

}
//============================================
async function customerLogout(){

    let res = await getJSON( 'customers', { logout: true } );
    if( res.ok ){ 
        Customer = null;
        Token = {};
    }
    return res || { ok: false };
}

//===========================================
// POSTS

async function findPosts( filters = {} )
{
    let geo = {}; //TODO geo location will be via URL like sort & dept
    let km = geo.km || 0;
    let geoArr = geo.geo;
    let rad = km/6378.1;
    let now = new Date( );

    let query = { 
        q: {
            geo: {
                $geoWithin: {
                    $centerSphere: [ geoArr, rad ]
                } }, 
            deleted: null,
            mod: null,
            _no: null,
            pub: { $lt: ejsonDate( now ) },
            exp: { $gt: ejsonDate( now ) }
            
        } };

    // if( !km )delete query.q.geo; //override for demo's
    if( !km )query.q.geo = null; //override for demo's

    if(WEB_ONLY)query.q.site = 'web';

    let urlQuery = getURLQuery();

    //TEXT SEARCH
    if( urlQuery.text )query.q.$text = { $search: urlQuery.text };

    //SORT 
    query.sort = getSorting(urlQuery.sort);
    // query.sort = { _id: 1 };
    if(query.sort.disc)query.q.disc = { $ne: null }; 

    //DEPT 
    let dept = urlQuery.dept;
    if(dept && dept != 'ALL')query.q.dept = dept;

    //RETAIL
    let retail =  urlQuery.retail;
    if(retail)query.q.org =  { "$in": retail.split(',') };

    query.limit = filters.limit || 20;
    query.skip =  filters.skip || 0;

    console.log( "QUERY", query );
    let res = await postJSON( 'posts', query );
    if(res.ok)console.log("POSTS", res.data.length);
    return res || {};
}

function getURLQuery(){
    let hash = location.hash;
    hash = hash.substring(3);
    if(!hash)return {};
    try {
        return  JSON.parse('{"' + decodeURI(hash).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    } catch (error) {
        console.log(error);
        return {}
    }
}

function getSorting( val ){

    if(!val)return { pub: -1, disc: -1 }; //Latest

    switch (val) {
        case 'LATEST': 
            return { pub: -1, disc: -1 }
        case 'DISC_H':
            return { disc: -1 }
        case 'DISC_L':
            return { disc: 1 }           
        case 'PRICE_L':
            return { now: 1 };
        case 'PRICE_H':
            return { now: -1 }
        default:
            return { pub: -1, disc: -1 }; //Latest
    }
}


//============================================
// USERS
async function getUser( user, pass ){

    if( !API_URL )return { ok: false, err: 'no region' };

    let params = {};
    if( user ){ //use cookies if null 
        params = {
            user,
            pass,
            remember: 'on',
            app: process.env.VUE_APP_NAME,
            ver: process.env.VUE_APP_VERSION
        };
        if ( params.user ) params.user = params.user.toLowerCase();
    }

    let rep = await getJSON( 'users', params );
    return rep || { ok: false };
}
//============================================
async function userLogout(){

    let res = await getJSON( 'users', { logout: true } );
    return res || { ok: false };
}

//============================================
// SITES
async function updateSite( siteData ){

    let res =  await postJSON( 'sites?write=1', siteData );
    return res || {};
}

async function getSites( org, site ){

    let res = await getJSON( 'sites', { org, site } );
    return res || { ok: false };
}


async function getSitePubCb( orgSite, cb ){

    if( Cache && Cache[orgSite] )return cb( Cache[orgSite] );

    if( !Que[orgSite] ){
        Que[orgSite] = [];
        Que[orgSite].push( cb );
    }
    else {
        Que[orgSite].push( cb );
        return;
    }

    let res = await getJSON( 'sites?&storeOnly=1&storePosts=' + orgSite );
    if( res.ok )Cache[orgSite] = res.data;
    let data = res.data || {};
    if( Que[orgSite] ){
        Que[orgSite].forEach( c => c( data ) );
        Que[orgSite] = null;
    }
    return data;
}

async function getSitePub( orgSite ){
    /*eslint no-debugger: 0*/
debugger;
    if( Cache && Cache[orgSite] )return Cache[orgSite];

    let res = await getJSON( 'sites?&storeOnly=1&storePosts=' + orgSite );
    if( res.ok && Cache ){
        Cache[orgSite] = res.data;
        return res.data;
    }
    return null;
}

async function getUniq( prop ){

    let res = await getJSON( 'sites', { uniq: prop } );
    return res || { ok: false };
}


//============================================
// HELPERS
function setTokenStr( str ){
    TokenStr = str;
}

async function getJSON( url, params={} ) {
 
    if( url.indexOf( 'http' ) == -1 )url = API_URL + url;

    try {
        console.log( "getJSON", url );
        let resp = await axios.get( url, { params, withCredentials: true, timeout: TIMEOUT } );
        return resp.data;

    } catch ( e ) {
        console.log( "axios.get", e );
        return null;
    }

}
async function postJSON( url, data ) {

    if( url.indexOf( 'http' ) == -1 )url = API_URL + url;
    try {
        console.log( "postJSON", url );
        let resp = await axios.post( url, data, { withCredentials: true, timeout: TIMEOUT } );
        return resp.data;

    } catch ( e ) {
        console.log( "axios.post", e );
        return null;
    }

}

async function postForm( url, data ) {

    url = API_URL + url;
    let body = new FormData();
    for ( let k in data ) body.set( k, data[k] );

    let resp = await axios( {
        method: "POST",
        url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: body
    } );
    return resp;
}


function getKm( geo1, geo2 ){
    let from = { latitude: geo1[1], longitude: geo1[0] };

    let to = {};
    if( !geo2 ){
        if( Customer && Customer.geo )to = { latitude: Customer.geo[1], longitude: Customer.geo[0] };
        else {
            let region = IPinfo;
            // console.log( region );
            if( region.latitude ){
                to = { latitude: region.latitude, longitude: region.longitude };
            }
        }
        if( !to.latitude )return null;
    } else to = { latitude: geo2[1], longitude: geo2[0] };

    let dist = getDistance( from, to, 1000 );
    return ( dist/1000 );
}



function ejsonDate( iso ) {

    let ep = new Date( iso ).getTime().toString();
    return { "$date": { "$numberLong": ep } }; 
}

// function copyObj(obj){
//     return JSON.parse( JSON.stringify(obj));
// }

// function ejsonDouble( num ){
//     return {"$numberDouble": `${num}`};
// }