<template>
  <div
    style="height: 200px;"
    class="tcc-back"
  >
    <span style="position: absolute; bottom: 0; left: 0; right: 0; margin: auto;">© {{ year }} The Clearance Club</span>
  </div>
</template>

<script>
export default {
    name: 'TccFooter',
    components: {},
    props: {

    },
    data() {
        return {
          year: ""
        };
    },
    watch: {
    },
    mounted() {
      this.year = new Date().getFullYear();
    },
    methods: {
    }
};
</script>

<style scoped>
.tcc-back {
  /* background-image: url("data:image/svg+xml;utf8,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='150' height='150' patternTransform='scale(5) rotate(100)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(208, 83%, 57%, 1)'/><path d='M150 15.2H0V6.56h150zm0 12.4v1.56H0V27.6zm0 28.37v6.6H0v-6.6zm0 39.24v2.33H0v-2.33zm0 22.54v.78H0v-.78zm0 13.21v8.16H0v-8.16zm0 10.1v1.17H0v-1.17z'  stroke-width='1' stroke='none' fill='hsla(208, 88%, 60%, 1)'/><path d='M150 50.53H0v-7.39h150zm0-34.58v.77H0v-.77zm0 15.54v3.88H0V31.5zm0 33.8v1.17H0v-1.17zm0 34.2v9.32H0V99.5zm0 27.2v2.72H0v-2.72z'  stroke-width='1' stroke='none' fill='hsla(208, 83%, 57%, 1)'/><path d='M150 77.73H0v-5.05h150zm0-72.66v1.16H0V5.07Zm0 13.98v4.28H0v-4.28zm0 100.25v3.11H0v-3.1zm0 27.2v3.5H0v-3.5z'  stroke-width='1' stroke='none' fill='hsla(208, 88%, 60%, 1)'/><path d='M150 40.42H0v-1.16h150zm0-37.3V4.3H0V3.12Zm0 27.2v.4H0v-.4Zm0 20.98v2.34H0V51.3zm0 31.42v9.32H0v-9.32zm0 29.2v3.5H0v-3.5zm0 13.21v.39H0v-.39zm0 19.04v1.17H0v-1.17z'  stroke-width='1' stroke='none' fill='hsla(208, 88%, 60%, 1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>") */
  background-color: #EBEBEB;
  /* background-color: #4A90E2; */

}
</style>