import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import PageNotFound from '../views/PageNotFound.vue';

Vue.use( VueRouter );

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
        /* webpackChunkName: "home" */
    },
    {
        path: '/about',
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue' )
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import( /* webpackChunkName: "search" */ '../views/Search.vue' )
    },
    {
        path: '/store/:orgsite?',
        name: 'Store',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "store" */ '../views/Store.vue' )
    },
    {
        path: '/post/:id',
        name: 'Post',
        meta: {
            scrollTop: true
        },
        component: () => import( /* webpackChunkName: "post" */ '../views/Post.vue' )
    },
    {
        path: '/manage',
        name: 'Manage',
        component: () => import( /* webpackChunkName: "customer" */ '../views/ManageCustomer.vue' )
    },
    {
        path: '/join',
        name: 'Join',
        component: () => import( /* webpackChunkName: "join" */ '../views/Join.vue' )
    },
    {
        path: '/admin/:id?',
        name: 'Admin',
        meta: {
            noCustomer: true
        },
        component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Admin.vue' )
    },
    {
        path: '/error/:msg',
        name: 'Error',
        props: true,
        component: () => import( /* webpackChunkName: "error" */ '../views/Error.vue' )
    },
    { path: "*", name: '404', component: PageNotFound }
];

let saveScroll;
const router = new VueRouter( {
    routes,
    scrollBehavior( to, from, savedPosition ) {
        //console.log( to, from, savedPosition );
        if( to.name == 'Home' && saveScroll )return saveScroll;
        if( from.name == 'Home' )saveScroll = savedPosition;
        if( to.meta.scrollTop )return { x: 0, y: 0 };
        return false;
    },
} );

export default router;
