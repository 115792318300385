<template>
  <div
    style="position: relative; display: block;"
    :style="{width, height}"
    class="base"
  >
    <div
      class="back"
      :class="{lg: lg}"
    />
    <div
      class="front"
      :class="{lg: lg}"
      @click="$emit('click')"
    >
      <span style="display: table-cell;vertical-align: middle;">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TccButton',
    components: {},
    props: {
        text: String,
        lg: Boolean,
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        }
    },
    data() {
        return {
        };
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
  .back {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #3898ec;
    border-radius: 4px;
  }
  .front {
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
    color: #333;
    padding: 2px 16px;
    transform: rotate(-8deg);
    background: rgb(240,190,58);
    background: linear-gradient(8deg, rgba(240,190,58,1) 0%, rgba(255,215,0,1) 100%, rgba(255,255,255,1) 100%);
    border-radius: 4px;
    border: 2px solid #555;
    cursor: pointer;
    text-shadow: none;
  }
  .front:hover {
    box-shadow: 1px 1px 3px #333;
  }
  .lg {
    padding: 8px 20px;
    font-size: 24px;
  }

</style>