<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn      
        v-if="user.joined || user.role"
        icon
        v-on="on"
      >
        <v-icon
          large
        >
          mdi-account-box-outline
        </v-icon>
      </v-btn>

      <!-- LOGIN BUTTON -->
      <v-btn
        v-else
        color="tccBlue"
        dark
        large
        @click="$emit('signin',true)"
      >
        LOGIN
      </v-btn>
    </template>

    <!-- CUSTOMER -->
    <v-list v-if="user.joined">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ user.email }}</v-list-item-title>
          <v-list-item-subtitle>Joined: {{ user.joined | niceDate }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push('manage')">
        <v-icon>
          mdi-cog-box
        </v-icon>
        <v-list-item-title>Manage your profile</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Signed in</v-list-item-title>
          <v-list-item-subtitle>From: {{ token.iat | niceDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>Valid till: {{ token.exp | niceDate }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout">
        <v-icon>
          mdi-logout
        </v-icon>
        <v-list-item-title>Sign this browser out</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-subheader class="mt-2">
        Version: {{ appVer }} {{ $http.Region() }} <br>
      </v-subheader>
    </v-list>

    <!-- USER -->
    <v-list
      v-if="user.role"
    >
      <v-list-item>
        <v-icon>
          mdi-shield-crown
        </v-icon>
        <v-list-item-title>{{ user.role }}</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>{{ user.user }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        Logout
      </v-list-item>
      <v-divider />
      <v-subheader class="mt-2">
        Version: {{ appVer }} {{ $http.Region() }} <br>
      </v-subheader>
    </v-list>

    <!-- NONE -->
    <v-list
      v-if="!user.pub && !user.joined"
    >
      <v-list-item
        :disabled="$route.name=='Join'"
        @click="$router.push('join')"
      >
        <v-icon>
          mdi-barcode
        </v-icon>
        <v-list-item-title>Join The Clearance Club</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$route.name != 'Join'"
        @click="$emit('signin',true)"
      >
        <v-icon>
          mdi-email-seal-outline
        </v-icon>
        <v-list-item-title>Sign in</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-subheader class="mt-2">
        Version: {{ appVer }} {{ $http.Region() }} <br>
      </v-subheader>
    </v-list>
  </v-menu>
</template>

<script>


export default {
    name: 'UserMenu',
    components: {
    },
    props: {
        user: {
            type: Object,
            default: () => {}
        },
        token: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ( {
        appVer: process.env.VUE_APP_VERSION,
    } ),
    mounted(){
        // console.log( this.$route.name );
    },
    methods: {
        logout(){
            this.$emit( 'logout' );
        }
    }
};
</script>

<style scoped>
  i.v-icon {
    color: var(--v-tccIcon-base) !important;
  }
</style>