<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.xs"
    transition="dialog-top-transition"
    max-width="600"
  >
    <v-card
      class="elevation-5 pa-8"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title
        :style="{color: msgColor}"
        v-html="msgText"
      >
        {{ msgText }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="custSignIn"
          v-model="valid"
          lazy-validation
        >
          <v-text-field

            v-model="email"
            label="Your email"
            name="email"
            prepend-icon="mdi-email"
            type="text"
            autocomplete="on"
            :rules="emailRules"
            @keydown.enter.prevent
            @keyup.enter.prevent="onJoin"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="warning"
          min-width="100px"
          @click="show = false"
        >
          {{ closeButton }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          color="primary"
          min-width="100px"
          @click="onJoin"
        >
          Send Sign in Email
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-debugger */

export default {
    name: 'CustomerSignIn',
    components: {},
    props: {

    },
    data() {
        return {
            show: false,
            msgText: "",
            msgColor: '',
            loading: false,
            closeButton: "CANCEL",
            valid: true,
            email: "",
            emailRules: [ ( v ) => !!v || "Your Email is required",
                ( v ) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test( v )
            ]
        };
    },
    computed: {
    },
    watch: {
        show( val ){
            if( !val )this.msg( '' );
        }
    },
    created() {},
    mounted() {

        let lastEmail = localStorage.getItem( "lastLoginEmail" );
        if( lastEmail )this.email = lastEmail;
        if( this.email )this.valid = false;
    },
    methods: {
        validate() {},
        async onJoin( evt ) {
            evt.preventDefault();
            let r = this.$refs.custSignIn.validate();
            if ( !r ) return;
            this.loading = true;
            this.msg( '' );
            this.valid = false;

            let data = {
                email: this.email
            };
            let res = await this.$http.postJSON( 'customers?signin=1',data );
            this.loading = false;
            console.log( res );
            this.closeButton = "CLOSE";
            if( res && res.ok ){
                this.msg( "We have sent a magic link to your inbox,<br> Click it to sign in" );
                localStorage.setItem( "lastLoginEmail",this.email );
                return;
            } 
            if( res && !res.ok && res.error == 'NO USER' )return this.msg( "We could not find you, try joining first its quick to do", 'var(--v-tccBlue-base)' );
            this.msg( "Something went wrong!",'negative' );
        },
        msg( msg,color ){

            this.msgColor = color || '#333';
            this.msgText = msg;

        }
    },
};
</script>
