<template>
  <div
    class="text-center"
    style="min-height: 100%; background-color: #ffffff;"
  >
    <!-- <v-row /> -->
    <div
      v-if="false"
      class="hero-bg"
    >
      <!-- <iframe
        :src="promoArea(customerSignedIn)"
        frameborder="0"
        scrolling="no"
        style="width: 80%; min-width: 300px; height: 200px; margin-top: 50px; margin-bottom: 50px"
      /> -->

      <v-row
        class="promo-row"
      >
        <v-col
          cols="12"
          class="text-h2"
          align-self="center"
          justify="end"
          style="font-family: 'Francois One'!important;"
        >
          Clearance Alerts
        </v-col>
        <v-col
          cols="12"
          align-self="center"
          class="text-h5"
        >
          Join The Clearance Club and be the first to know of deals online and in your area
          <tcc-button
            class="my-12 mx-auto"
            text="JOIN"
            width="200px"
            height="50px"
            @click="join"
          />
        </v-col>
      </v-row>
    </div>

    <!-- SEARCH AND FILTER BAR -->
    <div
      style="background-color: #f4f4f4;"
    >
      <v-container fluid>
        <v-row
          justify="center"
          style="background-color: #fff;"
          class="px-0"
        >
          <v-col
            cols="12"
            md="3"
            sm="12"
          >
            <v-text-field
              v-model="search"
              flat
              clearable
              prepend-icon="mdi-magnify"
              hide-details
              placeholder="I'm looking for..."
              style="font-size: larger"
              @keyup.enter="onSearch(search)"
              @click:clear="onSearch('')"
            />

            <!-- <tccSearch @onSearch="onSearch" /> -->
          </v-col>
          <v-col 
            cols="auto"
            md="3"
            sm="12"
          >
            <v-select
              v-model="retailFilter"
              :items="retailersList"
              label="Retailers"
              multiple
              style="min-width: 300px"
              @blur="onFilterRetailer"
              @change="refreshPosts=true"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  v-if="index < 3"
                  small
                  color="tccBlue"
                  dark
                >
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                  v-if="index === 3"
                  class="text-grey text-caption align-self-center"
                >
                  (+{{ retailFilter.length - 3 }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="auto"
            md="3"
            sm="6"
          >
            <tcc-dept
              id="dept"
              @change="findPosts"
            />
          </v-col>

          <v-col
            cols="auto"
            md="3"
            sm="6"
          >
            <tcc-sort
              id="sort"
              @change="findPosts"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container
      fluid
      style="background-color: #f4f4f4;"
    >
      <v-row
        justify="center"
        style="min-height: 100vh;"
        class="py-12"
      >
        <v-col
          v-if="search || retailFilter.length"
          cols="12"
          class="text-h5"
          style="color: #00000080"
        >
          Results for: {{ search }} {{ retailFilter.join(' , ') }}
        </v-col>
        <v-col
          v-for="(post) in posts"
          :key="post._id"
          cols="auto"
        >
          <postcard
            :post="post"
            :geo="geo"
            @logoClick="logoCardClick"
          />
        </v-col>
        <v-col v-if="!posts.length">
          <h2 style="color: #aaa">
            No Clearances Found :(
          </h2>
        </v-col>
      </v-row>

      <infinite-loading
        :identifier="loadMoreId"
        @infinite="loadMorePosts"
      >
        <div slot="no-results" />
        <div slot="no-more" />
      </infinite-loading> 

      <div v-if="eol && posts.length">
        Thats All For Now. <br> <v-btn
          variant="outlined"
          class="mt-4"
          @click="toTop()"
        >
          Back to Top
        </v-btn>
      </div>
    </v-container>

    <div
      class="hero-bg"
      style="min-height: 300px"
    />
    <tcc-foot />
  </div>
</template>

<script>
import postcard from "../components/PostCard.vue";
// import tccEdge from "@/components/tccEdge.vue";
import tccFoot from "@/components/tccFooter.vue";
import tccSort from "@/components/tccSort.vue";
import tccDept from "@/components/tccDept.vue";
import InfiniteLoading from 'vue-infinite-loading';
import TccButton from '../components/tccButton.vue';
// import tccSearch from "@/components/tccSearchBar.vue";

let skip = 0;
const LIMIT_PER_FETCH = 100;

const dummy = [
  {dummy: true},
  {dummy: true},
  {dummy: true},
  {dummy: true},
  {dummy: true},
]

export default {
  name: 'Home',
  components: {
    postcard,
    tccFoot,
    tccSort,
    tccDept,
    // tccSearch,
    InfiniteLoading,
    TccButton
  },
  data() {
    return {
      eol: false,
      loadMoreId: 0,
      search: "",
      posts: dummy,
      geo: null,
      customerSignedIn: false,
      customer: null,
      promoUrl: "",
      retailersList: require('../retailers.json'),
      retailFilter: [],
      refreshPosts: false
    };
  },
  beforeMount(){
    console.log("HOME BEFOREMOUNTED");
    // syncQuery(this);
  },
  async mounted() {
    console.log("HOME MOUNTED", window.location.pathname);
    if (window.location.pathname.length > 1) return;//not home path
    document.title = "The Clearance Club - Home";

    let km = 100;
    let res = await this.$http.getCustomer();
    console.log("getCustomer", res);
    if (res.ok) {
      this.geo = res.data.geo;
      this.customer = res.data;
      this.$emit('user', res.data, res.token);
      this.customerSignedIn = true;

      //if( this.$route.query.tk ) this.$router.replace( '/' ).catch( ()=>{} );//clean up url
    } else { //ANON CUSTOMER
      km = 0; //200;
      console.log("GEO DISABLED FOR DEMO'S");
      let reg = await this.$http.getRegionObj();
      if (reg.latitude) this.geo = [reg.longitude, reg.latitude];
      console.log("GEO", this.geo);

    }
    this.promoArea();
    console.log("KM", km);
    // if (this.$route.query.lat && this.$route.query.long) this.geo = [parseFloat(this.$route.query.long), parseFloat(this.$route.query.lat)];
    //WE need to also get any stores the customer follows
    // console.log(this.geo);

    let query = this.$http.getURLQuery();
    if(query.text)this.search = query.text;
    if(query.retail) this.retailFilter = query.retail.split(',');
    await this.findPosts();

    // res = await this.$http.findPosts( { skip, limit: LIMIT_PER_FETCH } );
    // if (res.ok){
    //    this.posts = res.data;
    //    skip += LIMIT_PER_FETCH;
    //    if(res.data.length < LIMIT_PER_FETCH)this.eol = true;
    // }

  },
  destroyed() {
    console.log("HOME UNMOUNTED");
  },
  methods: {

    promoArea(customer) {
      if (customer) return "/pages/promo1.html";
      return "/pages/join.html";
    },
    join(){
      window.open('/join/', '_blank');
    },
    async onSearch( txt ){
      // let txt = this.search;
      console.log("SEARCH", txt);

      let query = this.$http.getURLQuery();
      query.text = txt;
      this.$router.replace( { name: 'Home', query } ).catch(()=>{}) ;
      this.findPosts();
    },
    async findPosts(){
      //syncQuery(this, true);//update URL to match
      skip = 0;
      this.eol = false;
      this.posts = dummy;
      this.refreshPosts = false;
      let res = await this.$http.findPosts( { skip, limit: LIMIT_PER_FETCH });
      if (res.ok) this.posts = res.data;
      skip += LIMIT_PER_FETCH;
      if(res.data.length < LIMIT_PER_FETCH)this.eol = true;
      this.loadMoreId +=1;

    },
    logoCardClick( org ) {
      this.retailFilter = [org];
      this.refreshPosts = true;
      this.onFilterRetailer();
      //add the post to $store cache
    },
    async onFilterRetailer( ){
      if(!this.refreshPosts)return;
      console.log(this.retailFilter);

      let query = this.$http.getURLQuery();
      query.retail = this.retailFilter.join(',');
      this.$router.replace( { name: 'Home', query } ).catch(()=>{}) ;

      await this.findPosts();
    },
    async loadMorePosts( loader ){
      console.log("LOAD MORE", skip);
      let res = await this.$http.findPosts( { skip, limit: LIMIT_PER_FETCH, text: this.search });
      if (res.ok) {
        if(res.data.length){
          this.posts.push( ...res.data );
          skip += LIMIT_PER_FETCH;
          loader.loaded();
          if(res.data.length < LIMIT_PER_FETCH)this.eol = true;
        } else {
          loader.complete();  
          skip = 0;
          this.eol = true;
        }
      }   
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
  }

};

</script>

<style scoped>

.promo-row {
  /* border: 1px dashed red; */
  height: 100%;
  max-width: 75%;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 600px){
  .promo-row {
    max-width: 100%;
    width: 100%;
  }

}

@media screen and (max-width: 800px ) and ( min-width: 600px){
  .promo-row {
    text-shadow: 1px 1px 3px #333;
  }
}

</style>

