<template>
  <v-app>
    <!-- THIS is a popup dialog to get email and send signin email -->
    <Customer-sign-in ref="signin" />

    <v-snackbar
      v-model="snack.show"
      top
      :color="snack.color"
      :class="snack.class || 'grey--text text--darken-2 font-weight-medium'"
      :timeout="5000"
    >
      {{ snack.msg }}
    </v-snackbar>

    <!-- TOP BAR -->
    <v-app-bar
      app
      color="header"
      elevation="2"
      height="100px"
      elevate-on-scroll
      hide-on-scroll
    >
      <div
        class="d-flex align-left"
        style="min-width: 250px;"
      >
        <a href="/">
          <v-img
            alt="Logo"
            contain
            position="left center"
            src="@/assets/tcc-300.png"
            transition="scale-transition"
            height="80"
          />
        </a>
      </div>

      <!-- <tccSearch /> -->

      <v-spacer />


      <!-- USER MENU -->
      <user-menu
        v-if="false"
        :user="user"
        :token="token"
        @logout="customerLogout"
        @signin="$refs.signin.show=true"
      />
    </v-app-bar>

    <!-- MAIN PAGES -->
    <v-main>
      <keep-alive :include="['Home', 'Store']">
        <router-view
          ref="page"
          @snack="snackBox"
          @user="userMenu"
        />
      </keep-alive>
    </v-main>
    <tccMobileSearch />

    <!-- SCROLL TOP BUTTON -->
    <v-btn
      v-if="showScroll"
      class="scroll-top"
      dark
      color="tccBlue"
      @click="toTop()"
    >
      <v-icon dark>
        mdi-arrow-up
      </v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import UserMenu from './components/UserMenu.vue';
import CustomerSignIn from "./components/CustomerSignIn.vue";
// import tccSearch from "@/components/tccSearchBar.vue";
import tccMobileSearch from "@/components/tccMobileSearch.vue";
import "./style.css";
// debugger; // eslint-disable-line no-debugger
export default {
    name: 'App',
    components: {
        UserMenu,
        CustomerSignIn,
        tccMobileSearch
    },
    data: () => ( {
        token: {},
        user: {},
        showSignIn: false,
        showScroll: false,
        snack: {
            show: false,
            msg: "",
            color: "white",
            class: "",
        },
    } ),
    async mounted() {
        console.log( "APP MOUNTED", this.$route );
        window.onscroll = this.onScrollY;

        // if( window.location.hash == '#/admin' )return;


    },
    methods:{
        userMenu( user, token ){ //LET the #/admin page get the user then update here via $emit('user')

            if( user.role ){ //SUPER / ADMIN etc
                this.$vuetify.theme.themes.light.header = '#fbc117';
            }else {
                this.$vuetify.theme.themes.light.header = '#EBEBEB';
            }
            if( user )this.user = user;
            if( token )this.token = token;
        },
        async customerLogout(){

            this.token = {};
            this.user = {};
            this.$http.setTokenStr( "" );
            localStorage.setItem( "__cust_tk", '' );
            let res = await this.$http.customerLogout();
            if( res.ok ){
                this.snackBox( "Logged out" );
                this.$refs.page.customerSignedIn = false; //THIS will set the property "customerSignedIn" in any page that has it
            }
            else this.snackBox( "Error logging out", 'danger' );
        },
        onScrollY(){
          if(window.scrollY > window.innerHeight)this.showScroll = true;
          else this.showScroll = false;
          // console.log( window.scrollY, window.innerHeight);
        },
        toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
        snackBox( msg, color ){
            this.snack.msg = msg;
            this.snack.class = "white--text";
            this.snack.color = color || "success";
            this.snack.show = true;
        }
    }
};
</script>

<style>

.hero-bg {
  width: 100%;
  min-height: 300px;
  background: url(/img/tcc-bg1.png) no-repeat right center;
  background-size: auto, contain;
}
.scroll-top {
  position: fixed;
  bottom: 8px;
  right: 8px;
}

@media screen and (max-width: 600px){
  .hero-bg {
    background: rgb(56,152,236);
    background: linear-gradient(90deg, rgba(56,152,236,1) 0%, rgba(168,218,209,1) 100%);
    max-height: none;
  }
}

</style>