<template>
  <div
    style="position: relative; display: inline-block; max-width: 300px;"
  >
    <v-select
      v-model="val"
      hide-details
      :items="items"
      label="Sort by"
      item-text="text"
      item-value="val"
      flat
      @change="sortChange()"
    />
  </div>
</template>

<script>
export default {
    name: 'TccSort',
    components: {},
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            val: "",
            items: [
            { text: "Latest", val: 'LATEST' },
            { text: "Discount (Highest First)", val: 'DISC_H' },
            { text: "Discount (Lowest First)", val: 'DISC_L' },
            { text: "Price (Highest First)", val: 'PRICE_H' },
            { text: "Price (Lowest First)", val: 'PRICE_L' }
        ]
        };
    },
    watch: {
    },
    mounted() {

        let val = "";
        // if(this.id) val = localStorage.getItem(this.id);
        let query = this.$http.getURLQuery();
        if(this.id) val = query[this.id];
        if(val) this.val = val;
        else this.val = 'LATEST';

    },
    methods: {
        sortChange(){
            let query = this.$http.getURLQuery();
            query[this.id] = this.val;
            this.$router.replace( { name: 'Home', query } ).catch(()=>{}) ;
            this.$emit('change');
        }
    }
};
</script>

<style scoped>

</style>