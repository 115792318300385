import Vue from 'vue';
import App from './App.vue';
// import AppLanding from './views/About.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Http from './http.js';
// import {store} from './store.js';
import { inject } from '@vercel/analytics';
 inject();

 const humanize = require( 'tiny-human-time' ).short;

Vue.config.productionTip = false;
Vue.prototype.$store = {
    START_HASH: window.location.hash,
    START_PATH: window.location.pathname,
    siteCache: {}
};
Vue.prototype.$http = Http;

// let query = new URLSearchParams( window.location.search );
// let token = query.get( 'tk' );

Vue.filter( 'niceDate', function ( value ) {
    if ( !value ) return '';
    if( value.toString().length == 10 )value *= 1000;
    return new Date( value ).toString().substr( 0,15 );
} );

Vue.filter( 'ago', function ( iso ) {
    if ( !iso ) return '';
    let now = new Date();
    let thn = new Date( iso );
    let diff = now - thn;
    return humanize( diff );
    // return timeAgo( iso );
} );


( async function(){

    let params = ( new URL( document.location ) ).searchParams;
    //if( params.get( 'test' ) == null )return window.location.href = "/join/" ;
    let region = params.get( 'region' );
    let regionUrl;
    if( region )regionUrl = Http.setRegion( region );

    if( !regionUrl )region = await Http.getRegionObj();
 
    new Vue( {
        router,
        vuetify,
        render: h => h( App )
    } ).$mount( '#app' );

    // if(!countryCode)GO TO COUTNTRY PICKER PAGE?
    //console.log( window.location.pathname,window.location.hash );
    //IF theclearanceclub.com/org.site then goto store page
    // let tk = params.get( 'tk' );
    // if( tk ){
    //     localStorage.setItem( "__cust_tk", tk );
    //     history.replaceState( {},document.title,location.href.split( '?' )[0] );//cleanup url
    // }
    if( window.location.pathname.length > 1 && window.location.hash == '#/' )router.replace( { path: '/store' } ).catch();

}() );

