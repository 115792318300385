<template>
  <div
    style="position: relative; display: inline-block;  max-width: 300px;"
  >
    <v-select
      v-model="val"
      :items="items"
      label="Departments"
      item-text="text"
      item-value="val"
      flat
      
      @change="onChange()"
    />
  </div>
</template>

<script>

export default {
    name: 'TccSort',
    components: {},
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            val: "",
            items: require('../dept.json')
        };
    },
    watch: {
    },
    mounted() {
        console.log("DEPT MOUNTED");
        let val = "";
        let query = this.$http.getURLQuery();
        if(this.id) val = query[this.id];
        if(val) this.val = val;
        else this.val = 'ALL';

    },
    methods: {
        onChange(){
            console.log(this.val);
            let query = this.$http.getURLQuery();
            query[this.id] = this.val;
            this.$router.replace( { name: 'Home', query } ).catch(()=>{}) ;
            this.$emit('change');
        }
    }
};
</script>

<style scoped>

</style>