<template>
  <div class="d-flex align-center justify-center pt-12">
    <h1>404</h1>
  </div>
</template>

<script>



export default {
    name: 'PageNotFound',
    components: {},
    data() {
        return {};
    },
    mounted() {

    }

};
</script>
