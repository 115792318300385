<template>
  <div>
    <v-card
      v-if="post.dummy"
      class="dummy"
      width="300"
      height="400"
      color="#eee"
    />

    <v-card
      v-else
      :href="gotoUrl"
      :target="post.link? '_blank' : ''"
      :ripple="false"
      class="text-left"
      width="300"
      height="400"
      color="#fff"
    >
      <v-img
        v-once
        contain
        height="240"
        :src="choosePic()"
      />

      <span
        v-if="post.disc"
        class="discount"
      ><v-icon
        color="grey darken-3"
      >mdi-arrow-down-bold</v-icon>{{ post.disc }}%</span>

      <span
        v-if="post.q"
        class="qty"
      >{{ post.reason }}  {{ post.q }} left</span>
      <v-card-title class="title-limit">
        {{ post.title }}
      </v-card-title>

      <!-- STORE NAME & LOGO -->
      <span
        v-if="!storeMode"
        class="store-name"
      >
        <ul>
          <li v-if="sitePub.sub">{{ sitePub.sub }}, {{ sitePub.state }}</li>
          <li v-else>ONLINE </li>
          <li v-if="dist">
            {{ dist }}
          </li>
          <li
            v-if="false && post.stk"
            style="font-size: 12px"
          >Stock {{ post.stk }}</li>
        </ul>
      </span>

      <span
        v-if="sitePub.logo"
        class="store-logo"
        @click.prevent="logoClick"
      >
        <v-img
          max-width="80"
          contain
          :src="sitePub.logo"
        />
      </span>

      <span
        v-if="storeMode"
        class="desc"
      >
        {{ post.desc }}
      </span>

      <!-- PRICE -->

      <span
        v-if="post.was"
        class="was"
      >{{ price(post.was) }}</span>
      <span
        v-if="post.now"
        class="now"
      >{{ price( post.now ) }}</span>
      
      <span class="pub-ago">{{ post.pub | ago }} ago</span>

      <!-- MENU -->
      <v-menu
        bottom
        left
        min-width="292"
        max-width="292"
        content-class="elevation-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="stk"
            icon
            v-bind="attrs"
            small
            color="tccBlue"
            v-on="on"
            @click.prevent=""
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list
          dense
          color="tccBlue"
          outlined
          dark
        >
          <v-list-item 
            :href="'https://www.google.com/search?q=' + post.title"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-google</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Compare on Google</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Find more like this</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Report Issue</v-list-item-title>
          </v-list-item>
  
          <v-list-item
            v-if="post.stk"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title>Stock available:</v-list-item-title>
              <v-list-item-subtitle>{{ post.stk }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- END -->
    </v-card>
  </div>
</template>

<script>
export default {
    name: 'PostCard',
    components: {},
    props: {
        post: {
            type: Object,
            default: () => {}
        },
        geo: {
            type: Array,
            default: () => []
        },
        storeMode: {
            type: Boolean,
            default: false
        },
        currency: {
          type: String,
          default: "$"
        },
    },
    data() {
        return {
            dist: null,
            gotoUrl: "",
            sitePub: {}
        };
      },
    computed: {},
    watch: {
        // geo: function( val ){
        //     this.dist = this.$http.getKm( this.post.geo, val );
        // }
    },
    async mounted() {
        // console.log( "storeMode", this.storeMode );
        // this.dist = this.$http.getKm( this.post.geo, this.geo );
        // await this.getSitePub();
        
        if( !this.storeMode ){
            let orgSite = this.post.org + "." + this.post.site;
            this.$http.getSitePubCb( orgSite, data => this.sitePub = data ); //get site info for logo 
            
            if(this.post.link) this.gotoUrl = this.post.link;
            else this.gotoUrl = '#/post/' + this.post._id;

            // this.dist = "±23km";
            if(this.post.geo){
              let dis = this.$http.getKm( this.post.geo, this.geo );
              if( dis > 500 )this.dist = ">500km";
              else this.dist = "±" + dis + "km";
            }

        }

    },
    methods: {
        price( p ){

            if(typeof p == 'string')return p;
            return this.currency + Number(p).toFixed(2);
        },
        choosePic(){

            for( let i in this.post.images ){
              if( this.post.images[i] ){
                if(this.post.images[i].indexOf('ucarecdn.com') > 0)return this.post.images[i] + '-/resize/600x/';
                return this.post.images[i];
              }
            }
            return "./no-image.jpg";
        },
        async getSitePub(){

            let orgSite = this.post.org + "." + this.post.site;
            let data = await this.$http.getSitePub( orgSite );
            if( data.ok ){
                console.log( data );
                this.sitePub = data.site;
                console.log( this.sitePub );
            }
           
        },
        logoClick(){

          console.log(this.post.org);
          this.$emit('logoClick', this.post.org);
          // $router.push('/store/'+ post.org + '.' + post.site)

        },
        test(){
            console.log( "YES" );
        }

    }
};
</script>

<style scoped>


  .dummy {
    border: 2px dashed #ccc !important;
    box-shadow: none !important;
    opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
  }

  @keyframes skeleton-loading {
  0% {
    opacity: .3;
  }
  100% {
    opacity: 1;
  }
}

  .title-limit {

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  line-height: 1.3rem;
  word-break: normal;
  padding-bottom: 0;
  font-size: 1.1rem;
  }
  .was {
    min-width: 85px;
    text-align: center;
    position: absolute;
    top: 300px;
    left: 16px;
    text-decoration: line-through;
    background-color: #3898ec;
    color: #fff;
    padding: 2px 16px;
  }
  .now {
    min-width: 85px;
    text-align: center;
    position: absolute;
    top: 320px;
    left: 16px;
    font-size: larger;
    font-weight: bolder;
    /* background-color: #fed402; */
    color: #333;
    padding: 2px 16px;
    transform: rotate(-8deg);
    background: rgb(240,190,58);
    background: linear-gradient(8deg, rgba(240,190,58,1) 0%, rgba(255,215,0,1) 100%, rgba(255,255,255,1) 100%);
    /* border: 2px solid #333; */
  }
  .discount {
    position: absolute;
    top: 4px;
    left: 4px;
    font-weight: bolder;
    background-color: #fed402;
    color: #333;
    padding: 2px  4px;
    border-radius: 4px;
      /* transform: rotate(-5deg); */
    /* border: 2px solid #333; */
  }
  .qty {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 14px;
    border-radius: 12px;
    background-color: #3898ec;
    color: #fff;
    padding: 2px 8px;
  }
  .store-name {
    position: absolute;
    top: 300px;
    right: 16px;
    /* font-size: 14px; */
    color: #777;
    max-width: 60%;
    overflow: hidden;
    text-align: right;
  }
  ul, li {
  list-style-type: none;
  line-height: 100%;
}
 .store-name li:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }
 .store-name li:last-child {
   margin-right: 2px;
 }

  .desc {
    position: absolute;
    top: 300px;
    left: 50%;
    /* border: 1px solid #eee; */
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
  }

  .store-logo {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  .pub-ago {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  .stk {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .google {
    position: absolute;
    bottom: 8px;
    left: 50%;
  }
  .store-logo:hover {
    transform: rotate(-5deg);
  }
  .v-card--link:before {
  background: none;
}
</style>