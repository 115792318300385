<template>
  <span>
    <!-- CORNER BUTTON -->
    <div
      class="d-md-none corner-search elevation-5"
      @click="toggleSearchBox"
    >
      <v-icon

        style="transform: rotate(-45deg); position: absolute; left: 5px; bottom: 30px; "
        large
        color="#333"
      >
        mdi-magnify
      </v-icon> 
    </div>

    <v-dialog
      v-model="show"
      fullscreen
      transition="dialog-top-transition"
    >
      <v-card
        class="elevation-5 pa-8 d-flex flex-column"
      >
        <div class="bar" />
        <v-card-title> Search </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="searchText"
              name="search"
              type="text"
              autofocus
              autocomplete="on"
              outlined
              flat
              hide-details
              placeholder="I'm looking for..."
              style="font-size: larger"
              @keyup.enter="doSearch"
            />
            <v-select
              hide-details
              :items="['All', 'Clothing', 'Food', 'Tech', '...']"
              label="Categories"
              solo
              flat
            />
            <v-autocomplete
              solo
              flat
              hide-details=""
              :items="['Location 1', 'Location 2']"
              placeholder="Location"
            />
          </v-form>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            min-width="100px"
            @click="show=false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            min-width="100px"
            @click="doSearch"
          >
            Search
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
    name: 'TccMobileSearch',
    components: {},
    props: {

    },
    data() {
        return {
            show: false,
            searchText: ""
        };
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        doSearch(){
            if( this.searchText == "" )return;
            console.log( "SEARCHING", this.searchText );
            this.$router.push( { name: 'Search', query: { q: this.searchText } } );
            this.show = false;
        },
        toggleSearchBox(){
            this.show = !this.show;
        }
    }
};
</script>

<style scoped>

.corner-search {
    position: fixed; 
    bottom: -50px; 
    right: -50px;
    width: 100px;
    height: 100px;
    background-color: #fbc117;
    transform: rotate(45deg);

}
.bar {
    height: 20px;
    width: 100%;
    background-color: #fbc117;

}

</style>