import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use( Vuetify );

export default new Vuetify( {
    
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                header: '#EBEBEB',
                tccIcon: '#3898EC',
                tccPrimary: '#fbc117',
                tccYellow: '#fbc117',
                tcc20: '#fccd45',
                tcc50: '#fde08b',
                tcc80: '#fef3d1',
                tccd20: '#c99a12',
                tccd50: '#7e610c',
                tccd80: '#322705',
                tccMono: '#DBA404',

                tccBlue: '#3898EC',
                tccBlue20: '#60adf0',
                tccBlue50: '#9cccf6',
                tccBlue80: '#d7eafb',

                tccBlack: '#434343',
                negative: '#FB4F17',
                positive: '#C3FB17',
                neutral: '#FFB905',
                warning: '#FFB905'
            },
        },
    },
    
} );
